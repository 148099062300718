import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '../store/store.ts'
import { LoadingType } from '../types/loading-types.ts'

export type TickerMenuItem = {
    ticker: string;
    expiryDates: string[];
  };

interface GetOptionBracketState {
    optionBrackets?: BracketData;
    menuItems?: TickerMenuItem[];
    loading: LoadingType;
    error: string;
}

interface OptionData  {
    date: string;
    price: number;
    callPrice: number;
    call: number;
    putPrice: number;
    put: number;
    upperBound: string;
    lowerBound: string;
  };
  
interface ExpiryDateData {
expiryDate: string;
dates: OptionData[];
};

interface TickerData  {
ticker: string;
expiryDates: ExpiryDateData[];
};

interface BracketData  {
brackets: TickerData[];
};


const initialState: GetOptionBracketState = {
    optionBrackets: undefined,
    menuItems: undefined,
    loading: 'idle',
    error: ''
}
export const getOptionBrackets = createAsyncThunk<
    BracketData,
    undefined,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('data/getOptionBrackts', async (_, thunkAPI) => {

    try {
        const response = await axios.get<BracketData>(
            `https://api.option.ahmadreza.com/option-brackets?cache=cache-v2`, // TODO: change this
            {
                validateStatus: (status) => status < 400,
            }
        )
        return response.data
    } catch (error) {
        return thunkAPI.rejectWithValue({ error })
    }
})

export const getOptionBracketsSlice = createSlice({
    name: 'get-option-brackets',
    initialState,
    reducers: {
        resetLoadingStatus: (state) => {
            state.loading = 'idle'
            state.error = ''
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getOptionBrackets.pending, (state) => {
            state.loading = 'loading'
        })
        builder.addCase(
            getOptionBrackets.fulfilled,
            (state, { payload }) => {
                state.optionBrackets = payload
                state.menuItems = getMenuItems(payload)
                state.loading = 'loaded'
                state.error = ''
            }
        )
        builder.addCase(getOptionBrackets.rejected, (state, action) => {
            state.loading = 'error'
            state.error = action.error.message || ''
        })
    }
})

export const { resetLoadingStatus } = getOptionBracketsSlice.actions
export default getOptionBracketsSlice.reducer
function getMenuItems(bracketData: BracketData): TickerMenuItem[] |undefined {
    const result: TickerMenuItem[] = []
    bracketData.brackets.forEach(element => {
        result.push({
            ticker: element.ticker,
            expiryDates: element.expiryDates.map(e => e.expiryDate)
        })
    });
    return result;
}

