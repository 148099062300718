export const getData = (optionBracket, ticker, date, currentPrice = 400) => {
    const result = [];
    var counter = 1;

    const tickerData = optionBracket
        .brackets
        .find(b => b.ticker === ticker);

    const expiryDate = tickerData
        .expiryDates
        .find(b => b.expiryDate === date)
    
    const increment = 20 / expiryDate.dates.length

    expiryDate.dates.forEach(date => {
        result.push({
            id: 'call:' + date.date,
            group: 'call',
            price: Number(date.lowerBound),
            volume: counter
        });
        result.push({
            id: 'put:' + date.date,
            group: 'put',
            price: Number(date.upperBound),
            volume: counter
        });
        counter+=increment;
    });    
    result.push({
        id: 'Current:' + date.date,
        group: 'price',
        price: currentPrice,
        volume: 15
    });

    return result;
}
