import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { SwarmplotSample } from './components/swarmplot-sample.tsx';
import { getData } from './components/option-data.js';
import { useEffect } from 'react';
import { Breadcrumbs } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../hooks.ts'
import SidebarMenu from './components/sidebar-menu.tsx';
import { getOptionBrackets } from './get-option-brackets-slice.ts';
import { useParams } from 'react-router-dom';
import { getTickerCurrentData } from './get-ticker-current-data-slice.ts';


function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Option prediction
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Home() {
  const dispatch = useAppDispatch()
  const { ticker, expiryDate } = useParams();


  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState<{ id: string; group: string; price: any; volume: number; }[]>([]);
  const [max, setMax] = React.useState(0);
  const [min, setMin] = React.useState(0);
  const { optionBrackets } = useAppSelector((state) => state.getOptionBracketsState)
  const { tickersPrice: tickers } = useAppSelector((state) => state.getTickerCurrentDataState)

  useEffect(() => {      
    dispatch(getOptionBrackets())
    // TODO: Get the tickers data based on the option brackets
    dispatch(getTickerCurrentData('qqq'))
    dispatch(getTickerCurrentData('vnq'))
    dispatch(getTickerCurrentData('vti'))
  }, [dispatch])

  useEffect(() => {
    const fetchData = async (currentPrice) => {
      if (!optionBrackets || !ticker || !expiryDate)
        return;
      const result = await getData(optionBrackets, ticker, expiryDate, currentPrice);
      // Get max and min price in result
      let max = 0;
      let min = 0;
      result.forEach((item) => {
        if (item.price > max) {
          max = item.price;
        }
        if (item.price < min) {
          min = item.price;
        }
      });
      setMax(max);
      setMin(min);
      setData(result);
    };
    fetchData(410);
    const currentPrice = tickers?.findIndex((item) => item.ticker === ticker);
    if (tickers && currentPrice !== undefined && currentPrice !== -1) {
      fetchData(tickers[currentPrice].price);
    }
  }, [setData, optionBrackets, ticker, expiryDate, tickers]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Dashboard
            </Typography>
            <IconButton color="inherit">
              <Badge color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={true} hidden={!open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          {/* side bar menu  */}
          <SidebarMenu></SidebarMenu>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/">
                {ticker}
              </Link>
              <Typography color="text.primary">{expiryDate}</Typography>
            </Breadcrumbs>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 640,
                  }}
                >
                  <SwarmplotSample data={data} max={max} min={min} />
                </Paper>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}