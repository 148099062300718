// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/swarmplot
import { ResponsiveSwarmPlot } from '@nivo/swarmplot'
import React from 'react'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const SwarmplotSample = ({ data /* see data tab */}) => (
    <ResponsiveSwarmPlot
        data={data}
        groups={[ 'group A' ]}
        value="price"
        valueFormat="$.2f"
        valueScale={{ type: 'linear', min:'auto', max:'auto', reverse: false }}
        size={{
            key: 'volume',
            values: [
                4,
                20
            ],
            sizes: [
                6,
                20
            ]
        }}
        colors={{ scheme: 'accent' }}
        forceStrength={4}
        simulationIterations={100}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.6
                ],
                [
                    'opacity',
                    0.5
                ]
            ]
        }}
        margin={{ top: 80, right: 100, bottom: 80, left: 100 }}
        // axisTop={{
        //     tickSize: 10,
        //     tickPadding: 5,
        //     tickRotation: 0,
        //     legend: 'group is vertical, price is horizontal',
        //     legendPosition: 'middle',
        //     legendOffset: -46
        // }}
        // axisRight={{
        //     tickSize: 10,
        //     tickPadding: 5,
        //     tickRotation: 0,
        //     legend: 'price if vertical, group if horizontal',
        //     legendPosition: 'middle',
        //     legendOffset: 76
        // }}
        // axisBottom={{
        //     tickSize: 10,
        //     tickPadding: 5,
        //     tickRotation: 0,
        //     legend: 'group is vertical, price is horizontal',
        //     legendPosition: 'middle',
        //     legendOffset: 46
        // }}
        // axisLeft={{
        //     tickSize: 10,
        //     tickPadding: 5,
        //     tickRotation: 0,
        //     legend: 'price if vertical, group if horizontal',
        //     legendPosition: 'middle',
        //     legendOffset: -76
        // }}
    />
)