import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppDispatch, RootState } from '../store/store.ts'
import { LoadingType } from '../types/loading-types.ts'


interface GetTickerCurrentDataState {
    tickersPrice?: TickerData[];
    loading: LoadingType;
    error: string;
}

interface TickerData  {
    ticker: string;
    price: number;
};


const initialState: GetTickerCurrentDataState = {
    tickersPrice: undefined,
    loading: 'idle',
    error: ''
}

interface GetTickerCurrentDataResponse {
    chart: {
        result: {
            meta: {
                regularMarketPrice: number;
                symbol: string;
            };
        }[];
    };
}

export const getTickerCurrentData = createAsyncThunk<
    GetTickerCurrentDataResponse,
    string,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('data/getTickerCurrentData', async (ticker, thunkAPI) => {

    try {
        const response = await axios.get(
            `https://a4yn3bwmvc.execute-api.ap-southeast-2.amazonaws.com/prod/${ticker}?region=US&lang=en-US&includePrePost=false&interval=2m&useYfid=true&range=1d&corsDomain=finance.yahoo.com&.tsrc=finance`, // TODO: change this
            {
                validateStatus: (status) => status < 400,
            }
        )
        return response.data
    } catch (error) {
        return thunkAPI.rejectWithValue({ error })
    }
})

export const getTickerCurrentDataSlice = createSlice({
    name: 'get-option-brackets',
    initialState,
    reducers: {
        resetLoadingStatus: (state) => {
            state.loading = 'idle'
            state.error = ''
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTickerCurrentData.pending, (state) => {
            state.loading = 'loading'
        })
        builder.addCase(
            getTickerCurrentData.fulfilled,
            (state, { payload }) => {
                // copy state.tickers and add new ticker or update existing ticker
                if (state.tickersPrice) {
                    const tickers = [...state.tickersPrice]
                    const tickerIndex = tickers.findIndex((ticker) => ticker.ticker === payload.chart.result[0].meta.symbol.toLocaleLowerCase())
                    if (tickerIndex === -1) {
                        tickers.push({ticker: payload.chart.result[0].meta.symbol.toLocaleLowerCase(), price:payload.chart.result[0].meta.regularMarketPrice})
                    } else {
                        tickers[tickerIndex] = {ticker: payload.chart.result[0].meta.symbol.toLocaleLowerCase(), price:payload.chart.result[0].meta.regularMarketPrice}
                    }
                    state.tickersPrice = tickers
                } else {
                    state.tickersPrice = [{ticker: payload.chart.result[0].meta.symbol.toLocaleLowerCase(), price:payload.chart.result[0].meta.regularMarketPrice}]
                }
                state.loading = 'loaded'
                state.error = ''
            }
        )
        builder.addCase(getTickerCurrentData.rejected, (state, action) => {
            state.loading = 'error'
            state.error = action.error.message || ''
        })
    }
})

export const { resetLoadingStatus } = getTickerCurrentDataSlice.actions
export default getTickerCurrentDataSlice.reducer

