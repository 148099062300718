import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { Reducers } from '../reducers.ts'

export const rootReducer = combineReducers({
  ...Reducers
})
export const store = configureStore({
  reducer: rootReducer
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type
export type AppDispatch = typeof store.dispatch

export const useTypedSelector: TypedUseSelectorHook<
  RootState
> = useSelector
