import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { List, ListItemButton, ListItemIcon, ListItemText, Collapse } from "@mui/material"
import PaidIcon from '@mui/icons-material/Paid';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import React, { useState } from "react"
import { useAppSelector } from "../../hooks.ts"
import { TickerMenuItem } from "../get-option-brackets-slice.ts";
import { useNavigate } from "react-router-dom";

const TickerListItem = ({ tickerMenuItem }: { tickerMenuItem: TickerMenuItem }) => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(true)
    const handleClick = () => {
        setOpen(!open);
    };
    return <>
        <ListItemButton onClick={handleClick}>
            <ListItemIcon>
                <PaidIcon />
            </ListItemIcon>
            <ListItemText primary={tickerMenuItem.ticker} />
            {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {tickerMenuItem.expiryDates.map(expiryDate =>
                    <ListItemButton key={expiryDate} sx={{ pl: 4 }} onClick={(e) => navigate(`/${tickerMenuItem.ticker}/${expiryDate}`)}>
                        <ListItemIcon>
                            <CalendarMonthIcon />
                        </ListItemIcon>
                        <ListItemText primary={expiryDate} />
                    </ListItemButton>
                )}
            </List>
        </Collapse></>

}

const SidebarMenu = () => {
    const { menuItems } = useAppSelector((state) => state.getOptionBracketsState)

    return <List component="nav">
        {menuItems?.map(menuItem => (
            <TickerListItem key={menuItem.ticker} tickerMenuItem={menuItem} /> // Use TickerMenuItem as a JSX element
        ))}
    </List>
}
export default SidebarMenu;