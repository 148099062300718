import React from "react";
import Home from "./pages/Home.tsx";
import { Provider } from 'react-redux'
import { store } from './store/store.ts'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


const router = createBrowserRouter([
  {
    path: "/:ticker?/:expiryDate?",
    element: <Home></Home>,
  },
]);

function App() {
  return (
    <div className="App">
        <Provider store={store}>
          <RouterProvider router={router}></RouterProvider>
        </Provider>
    </div>
  );
}

export default App;
